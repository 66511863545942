.animated-dots .dot {
    display: inline-block;
    width: 2px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    animation: animate-dots 1s ease-in-out infinite;
  }
  
  @keyframes animate-dots {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }

  .dot.active {
    animation-delay: 0.1s;
  }
  
  .dot.active + .dot {
    animation-delay: 0.2s;
  }