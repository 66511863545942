@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


::-webkit-scrollbar {
  height: 1rem;
  width: .5rem
}

::-webkit-scrollbar:horizontal {
  height: .5rem;
  width: 1rem
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px
}

::-webkit-scrollbar-thumb {
  --tw-border-opacity: 1;
  background-color: rgba(217,217,227,.8);
  border-color: rgba(255,255,255,var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 1px
}

::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236,236,241,var(--tw-bg-opacity))
}

.dark ::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(86,88,105,var(--tw-bg-opacity))
}

.dark ::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(172,172,190,var(--tw-bg-opacity))
}

@media (min-width: 768px) {
  .scrollbar-trigger ::-webkit-scrollbar-thumb {
      visibility:hidden
  }

  .scrollbar-trigger:hover ::-webkit-scrollbar-thumb {
      visibility: visible
  }
}

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
      max-width:640px
  }
}

@media (min-width: 768px) {
  .container {
      max-width:768px
  }
}

@media (min-width: 1024px) {
  .container {
      max-width:1024px
  }
}

@media (min-width: 1280px) {
  .container {
      max-width:1280px
  }
}

@media (min-width: 1536px) {
  .container {
      max-width:1536px
  }
}
