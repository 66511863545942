:root {
    --button-bg: #606060;
    --button-hover-bg: #4d4d4d;
    --button-text: #ffffff;
    --button-bg-dark: #3b3b3b;
    --button-hover-bg-dark: #2a2a2a;
    --button-text-dark: #ffffff;
  }
  
  .text-selection-button {
    padding: 0.25rem;
    border-radius: 0.375rem;
    background-color: var(--button-bg);
    color: var(--button-text);
    transition-property: background-color, color;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .text-selection-button:hover {
    background-color: var(--button-hover-bg);
  }
  
  .dark .text-selection-button {
    background-color: var(--button-bg-dark);
    color: var(--button-text-dark);
  }
  
  .dark .text-selection-button:hover {
    background-color: var(--button-hover-bg-dark);
  }
  