@tailwind base;
@tailwind components;
@tailwind utilities;
blockquote,
dd,
dl,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
    margin: 0 !important;
}

.markdown {
    max-width: none
}

.markdown pre {
    background: rgb(40, 44, 52);
    color: #ffffff;
}

.markdown pre code {
    padding: 1rem;
    display: block;
}

.markdown ol {
    counter-reset: item
}

.markdown ul li {
    display: block;
    margin: 0;
    position: relative
}

.markdown ul li:before {
    content: "•";
    font-size: .875rem;
    line-height: 1.25rem;
    margin-left: -1rem;
    position: absolute
}


.markdown h1,
.markdown h2 {
    font-weight: 600
}

.markdown h2 {
    margin-bottom: 1rem;
    margin-top: 2rem
}

.markdown h3 {
    font-weight: 600
}

.markdown h3,
.markdown h4 {
    margin-bottom: .5rem;
    margin-top: 1rem
}

.markdown h4 {
    font-weight: 400
}

.markdown h5 {
    font-weight: 600
}

.markdown blockquote {
    --tw-border-opacity: 1;
    border-color: rgba(142, 142, 160, var(--tw-border-opacity));
    border-left-width: 2px;
    line-height: 1rem;
    padding-left: 1rem
}

.markdown ol,
.markdown ul {
    display: flex;
    flex-direction: column;
    padding-left: 1rem
}

.markdown ol li,
.markdown ol li>p,
.markdown ol ol,
.markdown ol ul,
.markdown ul li,
.markdown ul li>p,
.markdown ul ol,
.markdown ul ul {
    margin: 0
}

.markdown table {
    --tw-border-spacing-x: 0px;
    --tw-border-spacing-y: 0px;
    border-collapse: separate;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
    width: 100%
}

.markdown th {
    background-color: rgba(236, 236, 241, .2);
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-width: 1px;
    padding: .25rem .75rem
}

.markdown th:first-child {
    border-top-left-radius: .375rem
}

.markdown th:last-child {
    border-right-width: 1px;
    border-top-right-radius: .375rem
}

.markdown td {
    border-bottom-width: 1px;
    border-left-width: 1px;
    padding: .25rem .75rem
}

.markdown td:last-child {
    border-right-width: 1px
}

.markdown tbody tr:last-child td:first-child {
    border-bottom-left-radius: .375rem
}

.markdown tbody tr:last-child td:last-child {
    border-bottom-right-radius: .375rem
}

.markdown a {
    text-decoration-line: underline;
    text-underline-offset: 2px
}


.prose {
    color: var(--tw-prose-body);
    max-width: 65ch
}

.prose :where([class~=lead]):not(:where([class~=not-prose] *)) {
    color: var(--tw-prose-lead);
    font-size: 1.25em;
    line-height: 1.6;
    margin-bottom: 1.2em;
    margin-top: 1.2em
}

.prose :where(a):not(:where([class~=not-prose] *)) {
    color: var(--tw-prose-links);
    font-weight: 500;
    text-decoration: underline
}

.prose :where(strong):not(:where([class~=not-prose] *)) {
    color: var(--tw-prose-bold);
    font-weight: 600
}

.prose :where(a strong):not(:where([class~=not-prose] *)) {
    color: inherit
}

.prose :where(blockquote strong):not(:where([class~=not-prose] *)) {
    color: inherit
}

.prose :where(thead th strong):not(:where([class~=not-prose] *)) {
    color: inherit
}

.prose :where(ol):not(:where([class~=not-prose] *)) {
    list-style-type: decimal;
    margin-bottom: 1.25em;
    margin-top: 1.25em;
    padding-left: 1.625em
}

.prose :where(ol[type=A]):not(:where([class~=not-prose] *)) {
    list-style-type: upper-alpha
}

.prose :where(ol[type=a]):not(:where([class~=not-prose] *)) {
    list-style-type: lower-alpha
}

.prose :where(ol[type=A s]):not(:where([class~=not-prose] *)) {
    list-style-type: upper-alpha
}

.prose :where(ol[type=a s]):not(:where([class~=not-prose] *)) {
    list-style-type: lower-alpha
}

.prose :where(ol[type=I]):not(:where([class~=not-prose] *)) {
    list-style-type: upper-roman
}

.prose :where(ol[type=i]):not(:where([class~=not-prose] *)) {
    list-style-type: lower-roman
}

.prose :where(ol[type=I s]):not(:where([class~=not-prose] *)) {
    list-style-type: upper-roman
}

.prose :where(ol[type=i s]):not(:where([class~=not-prose] *)) {
    list-style-type: lower-roman
}

.prose :where(ol[type="1"]):not(:where([class~=not-prose] *)) {
    list-style-type: decimal
}

.prose :where(ul):not(:where([class~=not-prose] *)) {
    list-style-type: disc;
    margin-bottom: 1.25em;
    margin-top: 1.25em;
    padding-left: 1.625em
}

.prose :where(ol>li):not(:where([class~=not-prose] *))::marker {
    color: var(--tw-prose-counters);
    font-weight: 400
}

.prose :where(ul>li):not(:where([class~=not-prose] *))::marker {
    color: var(--tw-prose-bullets)
}

.prose :where(hr):not(:where([class~=not-prose] *)) {
    border-color: var(--tw-prose-hr);
    border-top-width: 1px;
    margin-bottom: 3em;
    margin-top: 3em
}

.prose :where(blockquote):not(:where([class~=not-prose] *)) {
    border-left-color: var(--tw-prose-quote-borders);
    border-left-width: .25rem;
    color: var(--tw-prose-quotes);
    font-style: italic;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 1.6em;
    margin-top: 1.6em;
    padding-left: 1em;
    quotes: "\201C" "\201D" "\2018" "\2019"
}

.prose :where(blockquote p:first-of-type):not(:where([class~=not-prose] *)):before {
    content: open-quote
}

.prose :where(blockquote p:last-of-type):not(:where([class~=not-prose] *)):after {
    content: close-quote
}

.prose :where(h1):not(:where([class~=not-prose] *)) {
    color: var(--tw-prose-headings);
    font-size: 2.25em;
    font-weight: 800;
    line-height: 1.1111111;
    margin-bottom: .8888889em;
    margin-top: 0
}

.prose :where(h1 strong):not(:where([class~=not-prose] *)) {
    color: inherit;
    font-weight: 900
}

.prose :where(h2):not(:where([class~=not-prose] *)) {
    color: var(--tw-prose-headings);
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.3333333;
    margin-bottom: 1em;
    margin-top: 2em
}

.prose :where(h2 strong):not(:where([class~=not-prose] *)) {
    color: inherit;
    font-weight: 800
}

.prose :where(h3):not(:where([class~=not-prose] *)) {
    color: var(--tw-prose-headings);
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: .6em;
    margin-top: 1.6em
}

.prose :where(h3 strong):not(:where([class~=not-prose] *)) {
    color: inherit;
    font-weight: 700
}

.prose :where(h4):not(:where([class~=not-prose] *)) {
    color: var(--tw-prose-headings);
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: .5em;
    margin-top: 1.5em
}

.prose :where(h4 strong):not(:where([class~=not-prose] *)) {
    color: inherit;
    font-weight: 700
}

.prose :where(img):not(:where([class~=not-prose] *)) {
    margin-bottom: 2em;
    margin-top: 2em
}

.prose :where(figure>*):not(:where([class~=not-prose] *)) {
    margin-bottom: 0;
    margin-top: 0
}

.prose :where(figcaption):not(:where([class~=not-prose] *)) {
    color: var(--tw-prose-captions);
    font-size: .875em;
    line-height: 1.4285714;
    margin-top: .8571429em
}

.prose :where(code):not(:where([class~=not-prose] *)) {
    color: var(--tw-prose-code);
    font-size: .875em;
    font-weight: 600
}

.prose :where(code):not(:where([class~=not-prose] *)):before {
    content: "`"
}

.prose :where(code):not(:where([class~=not-prose] *)):after {
    content: "`"
}

.prose :where(a code):not(:where([class~=not-prose] *)) {
    color: inherit
}

.prose :where(h1 code):not(:where([class~=not-prose] *)) {
    color: inherit
}

.prose :where(h2 code):not(:where([class~=not-prose] *)) {
    color: inherit;
    font-size: .875em
}

.prose :where(h3 code):not(:where([class~=not-prose] *)) {
    color: inherit;
    font-size: .9em
}

.prose :where(h4 code):not(:where([class~=not-prose] *)) {
    color: inherit
}

.prose :where(blockquote code):not(:where([class~=not-prose] *)) {
    color: inherit
}

.prose :where(thead th code):not(:where([class~=not-prose] *)) {
    color: inherit
}

.prose :where(pre):not(:where([class~=not-prose] *)) {
    background-color: transparent;
    border-radius: .375rem;
    color: currentColor;
    font-size: .875em;
    font-weight: 400;
    line-height: 1.7142857;
    margin: 0;
    overflow-x: auto;
    padding: 0
}

.prose :where(pre code):not(:where([class~=not-prose] *)) {
    background-color: transparent;
    border-radius: 0;
    border-width: 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0
}

.prose :where(pre code):not(:where([class~=not-prose] *)):before {
    content: none
}

.prose :where(pre code):not(:where([class~=not-prose] *)):after {
    content: none
}

.prose :where(table):not(:where([class~=not-prose] *)) {
    font-size: .875em;
    line-height: 1.7142857;
    margin-bottom: 2em;
    margin-top: 2em;
    table-layout: auto;
    text-align: left;
    width: 100%
}

.prose :where(thead):not(:where([class~=not-prose] *)) {
    border-bottom-color: var(--tw-prose-th-borders);
    border-bottom-width: 1px
}

.prose :where(thead th):not(:where([class~=not-prose] *)) {
    color: var(--tw-prose-headings);
    font-weight: 600;
    padding-bottom: .5714286em;
    padding-left: .5714286em;
    padding-right: .5714286em;
    vertical-align: bottom
}

.prose :where(tbody tr):not(:where([class~=not-prose] *)) {
    border-bottom-color: var(--tw-prose-td-borders);
    border-bottom-width: 1px
}

.prose :where(tbody tr:last-child):not(:where([class~=not-prose] *)) {
    border-bottom-width: 0
}

.prose :where(tbody td):not(:where([class~=not-prose] *)) {
    vertical-align: baseline
}

.prose :where(tfoot):not(:where([class~=not-prose] *)) {
    border-top-color: var(--tw-prose-th-borders);
    border-top-width: 1px
}

.prose :where(tfoot td):not(:where([class~=not-prose] *)) {
    vertical-align: top
}

.prose {
    --tw-prose-body: #374151;
    --tw-prose-headings: #111827;
    --tw-prose-lead: #4b5563;
    --tw-prose-links: #111827;
    --tw-prose-bold: #111827;
    --tw-prose-counters: #6b7280;
    --tw-prose-bullets: #d1d5db;
    --tw-prose-hr: #e5e7eb;
    --tw-prose-quotes: #111827;
    --tw-prose-quote-borders: #e5e7eb;
    --tw-prose-captions: #6b7280;
    --tw-prose-code: #111827;
    --tw-prose-pre-code: #e5e7eb;
    --tw-prose-pre-bg: #1f2937;
    --tw-prose-th-borders: #d1d5db;
    --tw-prose-td-borders: #e5e7eb;
    --tw-prose-invert-body: #d1d5db;
    --tw-prose-invert-headings: #fff;
    --tw-prose-invert-lead: #9ca3af;
    --tw-prose-invert-links: #fff;
    --tw-prose-invert-bold: #fff;
    --tw-prose-invert-counters: #9ca3af;
    --tw-prose-invert-bullets: #4b5563;
    --tw-prose-invert-hr: #374151;
    --tw-prose-invert-quotes: #f3f4f6;
    --tw-prose-invert-quote-borders: #374151;
    --tw-prose-invert-captions: #9ca3af;
    --tw-prose-invert-code: #fff;
    --tw-prose-invert-pre-code: #d1d5db;
    --tw-prose-invert-pre-bg: rgba(0, 0, 0, .5);
    --tw-prose-invert-th-borders: #4b5563;
    --tw-prose-invert-td-borders: #374151;
    font-size: 1rem;
    line-height: 1.75
}

.prose :where(p):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.25em;
    margin-top: 1.25em
}

.prose :where(video):not(:where([class~=not-prose] *)) {
    margin-bottom: 2em;
    margin-top: 2em
}

.prose :where(figure):not(:where([class~=not-prose] *)) {
    margin-bottom: 2em;
    margin-top: 2em
}

.prose :where(li):not(:where([class~=not-prose] *)) {
    margin-bottom: .5em;
    margin-top: .5em
}

.prose :where(ol>li):not(:where([class~=not-prose] *)) {
    padding-left: .375em
}

.prose :where(ul>li):not(:where([class~=not-prose] *)) {
    padding-left: .375em
}

.prose :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
    margin-bottom: .75em;
    margin-top: .75em
}

.prose :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 1.25em
}

.prose :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.25em
}

.prose :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 1.25em
}

.prose :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.25em
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~=not-prose] *)) {
    margin-bottom: .75em;
    margin-top: .75em
}

.prose :where(hr+*):not(:where([class~=not-prose] *)) {
    margin-top: 0
}

.prose :where(h2+*):not(:where([class~=not-prose] *)) {
    margin-top: 0
}

.prose :where(h3+*):not(:where([class~=not-prose] *)) {
    margin-top: 0
}

.prose :where(h4+*):not(:where([class~=not-prose] *)) {
    margin-top: 0
}

.prose :where(thead th:first-child):not(:where([class~=not-prose] *)) {
    padding-left: 0
}

.prose :where(thead th:last-child):not(:where([class~=not-prose] *)) {
    padding-right: 0
}

.prose :where(tbody td, tfoot td):not(:where([class~=not-prose] *)) {
    padding: .5714286em
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~=not-prose] *)) {
    padding-left: 0
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~=not-prose] *)) {
    padding-right: 0
}

.prose :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 0
}

.prose :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 0
}

.prose-sm :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
    margin-bottom: .5714286em;
    margin-top: .5714286em
}

.prose-sm :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 1.1428571em
}

.prose-sm :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.1428571em
}

.prose-sm :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 1.1428571em
}

.prose-sm :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.1428571em
}

.prose-sm :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 0
}

.prose-sm :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 0
}

.prose-base :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
    margin-bottom: .75em;
    margin-top: .75em
}

.prose-base :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 1.25em
}

.prose-base :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.25em
}

.prose-base :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 1.25em
}

.prose-base :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.25em
}

.prose-base :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 0
}

.prose-base :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 0
}

.prose-lg :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
    margin-bottom: .8888889em;
    margin-top: .8888889em
}

.prose-lg :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 1.3333333em
}

.prose-lg :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.3333333em
}

.prose-lg :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 1.3333333em
}

.prose-lg :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.3333333em
}

.prose-lg :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 0
}

.prose-lg :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 0
}

.prose-xl :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
    margin-bottom: .8em;
    margin-top: .8em
}

.prose-xl :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 1.2em
}

.prose-xl :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.2em
}

.prose-xl :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 1.2em
}

.prose-xl :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.2em
}

.prose-xl :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 0
}

.prose-xl :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 0
}

.prose-2xl :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
    margin-bottom: .8333333em;
    margin-top: .8333333em
}

.prose-2xl :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 1.3333333em
}

.prose-2xl :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.3333333em
}

.prose-2xl :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 1.3333333em
}

.prose-2xl :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 1.3333333em
}

.prose-2xl :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
    margin-top: 0
}

.prose-2xl :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
    margin-bottom: 0
}

.prose-invert {
    --tw-prose-body: var(--tw-prose-invert-body);
    --tw-prose-headings: var(--tw-prose-invert-headings);
    --tw-prose-lead: var(--tw-prose-invert-lead);
    --tw-prose-links: var(--tw-prose-invert-links);
    --tw-prose-bold: var(--tw-prose-invert-bold);
    --tw-prose-counters: var(--tw-prose-invert-counters);
    --tw-prose-bullets: var(--tw-prose-invert-bullets);
    --tw-prose-hr: var(--tw-prose-invert-hr);
    --tw-prose-quotes: var(--tw-prose-invert-quotes);
    --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
    --tw-prose-captions: var(--tw-prose-invert-captions);
    --tw-prose-code: var(--tw-prose-invert-code);
    --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
    --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
    --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
    --tw-prose-td-borders: var(--tw-prose-invert-td-borders)
}

/*  */
.blog-pre {
    position: relative;
    background: rgb(40, 44, 52);
    margin: 1rem 0 !important;
}

.code-bar {
    color: white;
    right: 10px;
    top: -2px;
    font-size: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: rgb(52, 53, 65);
    padding: 0 1rem;
    height: 2rem;
}

.code-bar .code-copy-btn {
    cursor: pointer;
}

.code-bar i {
    font-style: normal;
}

.before\:content-\[\"\"\]:before {
    --tw-content: "";
    content: var(--tw-content)
}

.before\:content-\[\'\"\'\]:before {
    --tw-content: '"';
    content: var(--tw-content)
}

.after\:h-\[15px\]:after {
    content: var(--tw-content);
    height: 15px
}

.after\:w-\[1px\]:after {
    content: var(--tw-content);
    width: 1px
}

.after\:bg-gray-300:after {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 197, 210, var(--tw-bg-opacity));
    content: var(--tw-content)
}

.after\:content-\[\"\"\]:after {
    --tw-content: "";
    content: var(--tw-content)
}

.after\:content-\[\'\"\'\]:after {
    --tw-content: '"';
    content: var(--tw-content)
}

.last\:mb-2:last-child {
    margin-bottom: .5rem
}

.last\:border-0:last-child {
    border-width: 0
}

.last\:after\:bg-transparent:last-child:after {
    background-color: transparent;
    content: var(--tw-content)
}

.checked\:border-blue-600:checked {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity))
}

.checked\:bg-blue-600:checked {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity))
}

.focus-within\:z-10[focus-within] {
    z-index: 10
}

.focus-within\:z-10:focus-within {
    z-index: 10
}

.focus-within\:border-indigo-600[focus-within] {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
}

.focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity))
}

.focus-within\:ring-1[focus-within] {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus-within\:ring-indigo-600[focus-within] {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
}

.focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity))
}

.hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(64, 65, 79, var(--tw-border-opacity))
}

.hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 217, 227, var(--tw-bg-opacity))
}

.hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 236, 241, var(--tw-bg-opacity))
}

.hover\:bg-\[\#2A2B32\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(42, 43, 50, var(--tw-bg-opacity))
}

.hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 53, 65, var(--tw-bg-opacity))
}

.hover\:bg-gray-500\/10:hover {
    background-color: hsla(240, 9%, 59%, .1)
}

.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(247, 247, 248, var(--tw-bg-opacity))
}

.hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 65, 79, var(--tw-bg-opacity))
}

.hover\:bg-white\/20:hover {
    background-color: hsla(0, 0%, 100%, .2)
}

.hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(197, 197, 210, var(--tw-bg-opacity))
}

.hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(210, 244, 211, var(--tw-bg-opacity))
}

.hover\:pr-4:hover {
    padding-right: 1rem
}

.hover\:pr-14:hover {
    padding-right: 3.5rem
}

.hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(142, 142, 160, var(--tw-text-opacity))
}

.hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 53, 65, var(--tw-text-opacity))
}

.hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(64, 65, 79, var(--tw-text-opacity))
}

.hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(32, 33, 35, var(--tw-text-opacity))
}

.hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
}

.hover\:text-black\/50:hover {
    color: rgba(0, 0, 0, .5)
}

.hover\:opacity-80:hover {
    opacity: .8
}

.hover\:opacity-75:hover {
    opacity: .75
}

.focus\:z-10:focus {
    z-index: 10
}

.focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(25, 195, 125, var(--tw-border-opacity))
}

.focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity))
}

.focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 163, 127, var(--tw-border-opacity))
}

.focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px
}

.focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color)
}

.focus\:ring-0:focus,
.focus\:ring-2:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)
}

.focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus\:ring-inset:focus {
    --tw-ring-inset: inset
}

.focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(25, 195, 125, var(--tw-ring-opacity))
}

.focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

.focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity))
}

.focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 163, 127, var(--tw-ring-opacity))
}

.focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(142, 142, 160, var(--tw-ring-opacity))
}

.focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px
}

.focus-visible\:ring-0.focus-visible {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.focus-visible\:ring-0:focus-visible {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 transparent;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent)
}

.disabled\:bottom-0\.5:disabled {
    bottom: .125rem
}

.disabled\:bottom-0:disabled {
    bottom: 0
}

.disabled\:cursor-auto:disabled {
    cursor: auto
}

.disabled\:text-gray-300:disabled {
    --tw-text-opacity: 1;
    color: rgba(197, 197, 210, var(--tw-text-opacity))
}

.disabled\:opacity-20:disabled {
    opacity: .2
}

.disabled\:hover\:bg-transparent:hover:disabled {
    background-color: transparent
}

.disabled\:hover\:bg-gray-900:hover:disabled {
    --tw-bg-opacity: 1;
    background-color: rgba(32, 33, 35, var(--tw-bg-opacity))
}

.group:hover .group-hover\:visible {
    visibility: visible
}

.group:hover .group-hover\:from-\[\#2A2B32\] {
    --tw-gradient-from: #2a2b32;
    --tw-gradient-to: rgba(42, 43, 50, 0);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to)
}

.group:hover .group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(142, 142, 160, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(64, 65, 79, var(--tw-text-opacity))
}

.group:hover .group-hover\:opacity-50 {
    opacity: .5
}

.dark .dark\:prose-invert {
    --tw-prose-body: var(--tw-prose-invert-body);
    --tw-prose-headings: var(--tw-prose-invert-headings);
    --tw-prose-lead: var(--tw-prose-invert-lead);
    --tw-prose-links: var(--tw-prose-invert-links);
    --tw-prose-bold: var(--tw-prose-invert-bold);
    --tw-prose-counters: var(--tw-prose-invert-counters);
    --tw-prose-bullets: var(--tw-prose-invert-bullets);
    --tw-prose-hr: var(--tw-prose-invert-hr);
    --tw-prose-quotes: var(--tw-prose-invert-quotes);
    --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
    --tw-prose-captions: var(--tw-prose-invert-captions);
    --tw-prose-code: var(--tw-prose-invert-code);
    --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
    --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
    --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
    --tw-prose-td-borders: var(--tw-prose-invert-td-borders)
}

.dark .dark\:border-gray-900\/50 {
    border-color: rgba(32, 33, 35, .5)
}

.dark .dark\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(197, 197, 210, var(--tw-border-opacity))
}

.dark .dark\:border-black\/20 {
    border-color: rgba(0, 0, 0, .2)
}

.dark .dark\:border-white\/20 {
    border-color: hsla(0, 0%, 100%, .2)
}

.dark .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(64, 65, 79, var(--tw-border-opacity))
}

.dark .dark\:border-white\/10 {
    border-color: hsla(0, 0%, 100%, .1)
}

.dark .dark\:border-black\/10 {
    border-color: rgba(0, 0, 0, .1)
}

.dark .dark\:border-white\/30 {
    border-color: hsla(0, 0%, 100%, .3)
}

.dark .dark\:bg-\[\#444654\] {
    --tw-bg-opacity: 1;
    background-color: rgba(68, 70, 84, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 53, 65, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 65, 79, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-800\/90 {
    background-color: rgba(52, 53, 65, .9)
}

.dark .dark\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(32, 33, 35, var(--tw-bg-opacity))
}

.dark .dark\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

.dark .dark\:bg-transparent {
    background-color: transparent
}

.dark .dark\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(86, 88, 105, var(--tw-bg-opacity))
}

.dark .dark\:bg-white\/10 {
    background-color: hsla(0, 0%, 100%, .1)
}

.dark .dark\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.dark .dark\:bg-white\/5 {
    background-color: hsla(0, 0%, 100%, .05)
}

.dark .dark\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity))
}

.dark .dark\:bg-\[\#272832\] {
    --tw-bg-opacity: 1;
    background-color: rgba(39, 40, 50, var(--tw-bg-opacity))
}

.dark .dark\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(142, 142, 160, var(--tw-bg-opacity))
}

.dark .dark\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(236, 236, 241, var(--tw-text-opacity))
}

.dark .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 190, var(--tw-text-opacity))
}

.dark .dark\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(197, 197, 210, var(--tw-text-opacity))
}

.dark .dark\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(217, 217, 227, var(--tw-text-opacity))
}

.dark .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.dark .dark\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity))
}

.dark .dark\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(142, 142, 160, var(--tw-text-opacity))
}

.dark .dark\:text-white\/50 {
    color: hsla(0, 0%, 100%, .5)
}

.dark .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(86, 88, 105, var(--tw-text-opacity))
}

.dark .dark\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(32, 33, 35, var(--tw-text-opacity))
}

.dark .dark\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity))
}

.dark .dark\:text-white\/70 {
    color: hsla(0, 0%, 100%, .7)
}

.dark .dark\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
}

.dark .dark\:opacity-100 {
    opacity: 1
}

.dark .dark\:shadow-\[0_0_15px_rgba\(0\2c 0\2c 0\2c 0\.10\)\] {
    --tw-shadow: 0 0 15px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 0 15px var(--tw-shadow-color);
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow)
}

.dark .dark\:ring-white\/20 {
    --tw-ring-color: hsla(0, 0%, 100%, .2)
}

.dark .dark\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(86, 88, 105, var(--tw-ring-opacity))
}

.dark .dark\:last\:border-0:last-child {
    border-width: 0
}

.dark .dark\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 53, 65, var(--tw-bg-opacity))
}

.dark .dark\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(64, 65, 79, var(--tw-bg-opacity))
}

.dark .dark\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(32, 33, 35, var(--tw-bg-opacity))
}

.dark .dark\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 217, 227, var(--tw-bg-opacity))
}

.dark .dark\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 236, 241, var(--tw-bg-opacity))
}

.dark .dark\:hover\:bg-gray-500\/10:hover {
    background-color: hsla(240, 9%, 59%, .1)
}

.dark .dark\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 217, 227, var(--tw-text-opacity))
}

.dark .dark\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.dark .dark\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 190, var(--tw-text-opacity))
}

.dark .dark\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity))
}

.dark .dark\:hover\:text-white\/50:hover {
    color: hsla(0, 0%, 100%, .5)
}

.dark .dark\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity))
}

.dark .dark\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity))
}

.dark .dark\:disabled\:text-gray-400:disabled {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 190, var(--tw-text-opacity))
}

.dark .dark\:disabled\:hover\:bg-transparent:hover:disabled {
    background-color: transparent
}

.dark .dark\:disabled\:hover\:bg-white:hover:disabled {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity))
}

.dark .disabled\:dark\:hover\:text-gray-400:hover:disabled {
    --tw-text-opacity: 1;
    color: rgba(172, 172, 190, var(--tw-text-opacity))
}

@media (min-width:640px) {
    .sm\:absolute {
        position: absolute
    }

    .sm\:order-2 {
        order: 2
    }

    .sm\:order-1 {
        order: 1
    }

    .sm\:m-6 {
        margin: 1.5rem
    }

    .sm\:my-8 {
        margin-bottom: 2rem;
        margin-top: 2rem
    }

    .sm\:mx-7 {
        margin-left: 1.75rem;
        margin-right: 1.75rem
    }

    .sm\:mt-0 {
        margin-top: 0
    }

    .sm\:mt-4 {
        margin-top: 1rem
    }

    .sm\:mb-16 {
        margin-bottom: 4rem
    }

    .sm\:mt-\[20vh\] {
        margin-top: 20vh
    }

    .sm\:block {
        display: block
    }

    .sm\:h-10 {
        height: 2.5rem
    }

    .sm\:w-full {
        width: 100%
    }

    .sm\:w-10 {
        width: 2.5rem
    }

    .sm\:w-\[calc\(\(100\%-12px\)\/2\)\] {
        width: calc(50% - 6px)
    }

    .sm\:max-w-sm {
        max-width: 24rem
    }

    .sm\:max-w-lg {
        max-width: 32rem
    }

    .sm\:max-w-xl {
        max-width: 36rem
    }

    .sm\:max-w-2xl {
        max-width: 42rem
    }

    .sm\:max-w-4xl {
        max-width: 56rem
    }

    .sm\:max-w-\[480px\] {
        max-width: 480px
    }

    .sm\:max-w-md {
        max-width: 28rem
    }

    .sm\:translate-y-0 {
        --tw-translate-y: 0px
    }

    .sm\:scale-95,
    .sm\:translate-y-0 {
        -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .sm\:scale-95 {
        --tw-scale-x: .95;
        --tw-scale-y: .95
    }

    .sm\:scale-100 {
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .sm\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }

    .sm\:grid-rows-2 {
        grid-template-rows: repeat(2, minmax(0, 1fr))
    }

    .sm\:flex-row {
        flex-direction: row
    }

    .sm\:flex-row-reverse {
        flex-direction: row-reverse
    }

    .sm\:items-center {
        align-items: center
    }

    .sm\:justify-between {
        justify-content: space-between
    }

    .sm\:gap-4 {
        gap: 1rem
    }

    .sm\:gap-12 {
        gap: 3rem
    }

    .sm\:border-t-0 {
        border-top-width: 0
    }

    .sm\:border-r {
        border-right-width: 1px
    }

    .sm\:p-0 {
        padding: 0
    }

    .sm\:p-6 {
        padding: 1.5rem
    }

    .sm\:p-4 {
        padding: 1rem
    }

    .sm\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .sm\:px-0 {
        padding-left: 0;
        padding-right: 0
    }

    .sm\:pb-6 {
        padding-bottom: 1.5rem
    }

    .sm\:pr-6 {
        padding-right: 1.5rem
    }

    .sm\:pl-3 {
        padding-left: .75rem
    }

    .sm\:pb-1 {
        padding-bottom: .25rem
    }

    .sm\:pb-2 {
        padding-bottom: .5rem
    }

    .sm\:text-left {
        text-align: left
    }

    .sm\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem
    }

    .sm\:text-xs {
        font-size: .75rem;
        line-height: 1rem
    }

    .sm\:text-base {
        font-size: 1rem;
        line-height: 1.5rem
    }

    .sm\:text-\[40px\] {
        font-size: 40px
    }

    .sm\:duration-300 {
        transition-duration: .3s
    }

    .sm\:duration-500 {
        transition-duration: .5s
    }
}

@media (min-width:768px) {
    .md\:invisible {
        visibility: hidden
    }

    .md\:fixed {
        position: fixed
    }

    .md\:inset-y-0 {
        bottom: 0;
        top: 0
    }

    .md\:bottom-2\.5 {
        bottom: .625rem
    }

    .md\:bottom-2 {
        bottom: .5rem
    }

    .md\:right-2 {
        right: .5rem
    }

    .md\:left-2 {
        left: .5rem
    }

    .md\:bottom-\[120px\] {
        bottom: 120px
    }

    .md\:m-auto {
        margin: auto
    }

    .md\:mx-4 {
        margin-left: 1rem;
        margin-right: 1rem
    }

    .md\:mb-auto {
        margin-bottom: auto
    }

    .md\:mb-2 {
        margin-bottom: .5rem
    }

    .md\:mt-3 {
        margin-top: .75rem
    }

    .md\:flex {
        display: flex
    }

    .md\:hidden {
        display: none
    }

    .md\:h-48 {
        height: 12rem
    }

    .md\:h-full {
        height: 100%
    }

    .md\:min-h-\[34px\] {
        min-height: 34px
    }

    .md\:w-\[260px\] {
        width: 260px
    }

    .md\:w-full {
        width: 100%
    }

    .md\:w-1\/2 {
        width: 50%
    }

    .md\:w-\[100\%\] {
        width: 100%
    }

    .md\:w-auto {
        width: auto
    }

    .md\:max-w-2xl {
        max-width: 42rem
    }

    .md\:flex-col {
        flex-direction: column
    }

    .md\:gap-3 {
        gap: .75rem
    }

    .md\:gap-6 {
        gap: 1.5rem
    }

    .md\:gap-4 {
        gap: 1rem
    }

    .md\:gap-2 {
        gap: .5rem
    }

    .md\:border {
        border-width: 1px
    }

    .md\:border-t-0 {
        border-top-width: 0
    }

    .md\:border-transparent {
        border-color: transparent
    }

    .md\:\!bg-transparent {
        background-color: transparent !important
    }

    .md\:bg-vert-light-gradient {
        background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 13.94%, #fff 54.73%)
    }

    .md\:py-6 {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem
    }

    .md\:py-3 {
        padding-bottom: .75rem;
        padding-top: .75rem
    }

    .md\:px-4 {
        padding-right: 1rem
    }

    .md\:pl-4,
    .md\:px-4 {
        padding-left: 1rem
    }

    .md\:pl-0 {
        padding-left: 0
    }

    .md\:pl-\[260px\] {
        padding-left: 260px
    }

    .md\:pt-3 {
        padding-top: .75rem
    }

    .md\:pb-6 {
        padding-bottom: 1.5rem
    }

    .md\:pb-5 {
        padding-bottom: 1.25rem
    }

    .md\:text-justify {
        text-align: justify
    }

    .md\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem
    }

    .md\:last\:mb-6:last-child {
        margin-bottom: 1.5rem
    }

    .md\:disabled\:bottom-1:disabled {
        bottom: .25rem
    }

    .group:hover .md\:group-hover\:visible {
        visibility: visible
    }

    .dark .md\:dark\:border-transparent {
        border-color: transparent
    }

    .dark .dark\:md\:bg-vert-dark-gradient {
        background-image: linear-gradient(180deg, rgba(53, 55, 64, 0), #353740 58.85%)
    }
}

@media (min-width:1024px) {
    .lg\:visible {
        visibility: visible
    }

    .lg\:absolute {
        position: absolute
    }

    .lg\:relative {
        position: relative
    }

    .lg\:top-0 {
        top: 0
    }

    .lg\:right-0 {
        right: 0
    }

    .lg\:top-1\/2 {
        top: 50%
    }

    .lg\:-left-3 {
        left: -.75rem
    }

    .lg\:left-auto {
        left: auto
    }

    .lg\:-right-3 {
        right: -.75rem
    }

    .lg\:m-auto {
        margin: auto
    }

    .lg\:mx-auto {
        margin-left: auto;
        margin-right: auto
    }

    .lg\:mt-0 {
        margin-top: 0
    }

    .lg\:mb-0 {
        margin-bottom: 0
    }

    .lg\:hidden {
        display: none
    }

    .lg\:w-3\/5 {
        width: 60%
    }

    .lg\:w-auto {
        width: auto
    }

    .lg\:w-1\/2 {
        width: 50%
    }

    .lg\:w-2\/3 {
        width: 66.666667%
    }

    .lg\:w-\[calc\(100\%-20px\)\] {
        width: calc(100% - 20px)
    }

    .lg\:w-\[calc\(100\%-115px\)\] {
        width: calc(100% - 115px)
    }

    .lg\:w-1\/3 {
        width: 33.333333%
    }

    .lg\:w-\[calc\(\(100\%-24px\)\/3\)\] {
        width: calc(33.33333% - 8px)
    }

    .lg\:max-w-sm {
        max-width: 24rem
    }

    .lg\:max-w-none {
        max-width: none
    }

    .lg\:max-w-2xl {
        max-width: 42rem
    }

    .lg\:max-w-3xl {
        max-width: 48rem
    }

    .lg\:max-w-5xl {
        max-width: 64rem
    }

    .lg\:translate-y-0 {
        --tw-translate-y: 0px
    }

    .lg\:translate-x-full,
    .lg\:translate-y-0 {
        -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .lg\:translate-x-full {
        --tw-translate-x: 100%
    }

    .lg\:-translate-y-1\/2 {
        --tw-translate-y: -50%
    }

    .lg\:-translate-x-full,
    .lg\:-translate-y-1\/2 {
        -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    }

    .lg\:-translate-x-full {
        --tw-translate-x: -100%
    }

    .lg\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }

    .lg\:gap-1 {
        gap: .25rem
    }

    .lg\:self-center {
        align-self: center
    }

    .lg\:rounded-lg {
        border-radius: .5rem
    }

    .lg\:rounded-none {
        border-radius: 0
    }

    .lg\:border {
        border-width: 1px
    }

    .lg\:border-0 {
        border-width: 0
    }

    .lg\:p-6 {
        padding: 1.5rem
    }

    .lg\:p-0 {
        padding: 0
    }

    .lg\:px-14 {
        padding-left: 3.5rem;
        padding-right: 3.5rem
    }

    .lg\:px-0 {
        padding-left: 0;
        padding-right: 0
    }

    .lg\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .lg\:pt-32 {
        padding-top: 8rem
    }

    .lg\:pl-2 {
        padding-left: .5rem
    }

    .lg\:text-left {
        text-align: left
    }
}

@media (min-width:1280px) {
    .xl\:w-1\/4 {
        width: 25%
    }

    .xl\:max-w-3xl {
        max-width: 48rem
    }

    .xl\:max-w-7xl {
        max-width: 80rem
    }

    .xl\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }
}

code,
pre {
    font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important
}

code[class=language-plaintext] {
    white-space: pre-line
}

code.hljs,
code[class*=language-],
pre[class*=language-] {
    word-wrap: normal;
    background: none;
    color: #fff;
    -webkit-hyphens: none;
    hyphens: none;
    line-height: 1.5;
    tab-size: 4;
    text-align: left;
    white-space: pre;
    word-break: normal;
    word-spacing: normal
}

pre[class*=language-] {
    border-radius: .3em;
    overflow: auto
}

:not(pre)>code.hljs,
:not(pre)>code[class*=language-] {
    border-radius: .3em;
    padding: .1em;
    white-space: normal
}

.hljs-comment {
    color: hsla(0, 0%, 100%, .5)
}

.hljs-meta {
    color: hsla(0, 0%, 100%, .6)
}

.hljs-built_in,
.hljs-class .hljs-title {
    color: #e9950c
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword,
.hljs-literal {
    color: #2e95d3
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
    color: #00a67d
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
    color: #df3079
}

.hljs-bullet,
.hljs-link,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
    color: #f22c3d
}

.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
    color: #a9aec1
}

.token.punctuation {
    color: #fefefe
}

.token.constant,
.token.deleted,
.token.property,
.token.symbol,
.token.tag {
    color: #ffa07a
}

.token.boolean,
.token.number {
    color: #00e0e0
}

.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
    color: #abe338
}

.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url,
.token.variable {
    color: #00e0e0
}

.token.atrule,
.token.attr-value,
.token.function {
    color: gold
}

.token.keyword {
    color: #00e0e0
}

.token.important,
.token.regex {
    color: gold
}

.token.bold,
.token.important {
    font-weight: 700
}

.token.italic {
    font-style: italic
}

.token.entity {
    cursor: help
}

@media screen and (-ms-high-contrast:active) {

    code[class*=language-],
    pre[class*=language-] {
        background: window;
        color: windowText
    }

    :not(pre)>code[class*=language-],
    pre[class*=language-] {
        background: window
    }

    .token.important {
        background: highlight;
        color: window;
        font-weight: 400
    }

    .token.atrule,
    .token.attr-value,
    .token.function,
    .token.keyword,
    .token.operator,
    .token.selector {
        font-weight: 700
    }

    .token.attr-value,
    .token.comment,
    .token.doctype,
    .token.function,
    .token.keyword,
    .token.operator,
    .token.property,
    .token.string {
        color: highlight
    }

    .token.attr-value,
    .token.url {
        font-weight: 400
    }
}
