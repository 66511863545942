.scroll-btn {
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  opacity: 0.5;
  /* set the opacity to 50% */
  transition: opacity 0.3s ease-in-out;
  /* add a transition effect */
}

.scroll-btn:hover {
  opacity: 1;
  /* set the opacity to 100% on hover */
}

.scroll-btn svg {
  fill: #636363;
  width: 20px;
  height: 20px;
}

.btn-top-down {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1;
}

@media (max-width: 768px) {
  .btn-top-down {
    top: 48px;
  }
}